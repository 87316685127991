// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

require("local-time").start();

import "jquery";
import "popper.js";
import "bootstrap-html5sortable";
import "bootstrap";
import "@nathanvda/cocoon";

import copy from "copy-to-clipboard";
import "packs/admin/users/import";
import "packs/admin/users/edit";

import "../stylesheets/styles.scss";

$(document).on("turbolinks:load", function () {
  $(".add-tag-button").click(function () {
    var tag_value = $(this).data("value");
    var tags_input = $(".system-tags input");
    var current_tags = tags_input.val();

    if (String(current_tags).length > 0) {
      var new_tags = current_tags + ", " + tag_value;
    } else {
      var new_tags = tag_value;
    }

    tags_input.val(new_tags);
  });

  $(".sortable").sortable({
    items: ".form-check",
    forcePlaceholderSize: true,
  });

  $("#copy-to-clipboard-btn").on("click", () => {
    copy(document.getElementById("copy-to-clipboard-content").textContent);
    const button = document.getElementById("copy-to-clipboard-btn");
    const buttonText = button.textContent;
    button.textContent = "Copied!";
    setTimeout(() => {
      button.textContent = buttonText;
    }, 5000);
  });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
