import _ from "lodash";

const searchUsers = (text, role, callback) => {
  $.ajax(`/users/search?q=${text}&role=${role}`, {
    method: "get",
    headers: { "X-CSRF-Token": $("meta[name=csrf-token]").content },
  }).done(callback);
};

const debouncedSearchUsers = _.debounce(searchUsers, 500);

$(document).on("input", "input.search_coach", function (e) {
  const text = $(e.target).val();
  if (text.length < 3) {
    return;
  }

  debouncedSearchUsers(text, "coach", (data) => {
    const select = $(".coaches_list");
    select.empty();
    select.append(new Option("", ""));
    data.data.forEach((user) => {
      select.append(new Option(user.email, user.id));
    });
  });

  return false;
});

$(document).on("input", "input.search_parent", function (e) {
  const text = $(e.target).val();
  if (text.length < 3) {
    return;
  }

  debouncedSearchUsers(text, "parent", (data) => {
    const select = $(".parents_list");
    select.empty();
    select.append(new Option("", ""));
    data.data.forEach((user) => {
      select.append(new Option(user.email, user.id));
    });
  });

  return false;
});
